import React from 'react';
import { Link } from 'react-router-dom';
import liveizy from '../../static/images/liveizy-logo-flat@2x.png';
import './footer.css';

export function Footer() {
  return (
    <>
      <footer className="footer">
        <section className="footer__grid">
          <div className="footer__box">
            <div className="logo">
              <img src={liveizy} alt="" />
            </div>
            <p>
              you can save towards your rent and reduce your cost of living by
              joining liveizy community today.
            </p>
          </div>
          <div className="footer__contact--container">
            <div className="footer__contact--wrapper">
              <div className="footer__contact">
                <h3>Company</h3>
                <Link to="/izyabout">About Us</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/privacy-policy">Terms of use</Link>

              </div>
              <div className="footer__contact">
                <h3>Products</h3>
                <Link to="/izyrent">izyRent</Link>
                {/* <Link to="/izyproman">izyProman</Link> */}
                {/* <Link to="/izyfood">izyFood</Link> */}
                <Link to="/izypay">izyPay</Link>
              </div>
              <div className="footer__contact">
                <h3>Contact</h3>
                <p>
                  <b>Address: </b>27a Ajose Adeogun, VI, Lagos, Nigeria <br /> <br />
                  <b>Mobile: </b> +234(0) 806 773 0381 <br />
                  <b> Email us: </b>askus@liveizy.com
                </p>

              </div>
            </div>
          </div>
        </section>
      </footer>

      <section className="copyright">
        <section className="inner__container">
          <div className="copyright__text">
            © copyright {new Date().getFullYear()} by Liveizy.com
          </div>
        </section>
      </section>
    </>
  );
}
